.back
  padding: 8px 32px 32px 32px
  background: #006cb9 url(http://www.transparenttextures.com/patterns/otis-redding.png)

.back-gradiente
   background: rgb(0, 108, 185)
   background: linear-gradient(90deg, rgba(0, 108, 185) 0%, #2082c7 65%, #62aee4 100%)


.graph-header
  margin-bottom: 46px