@import "~bootstrap/scss/bootstrap"
@import "../../basedata/variables"

.form-label
  color: $pm-primary

.label-error
  margin-top: 1rem !important
  color: $red-400

.form-control
  border-color: $pm-primary !important

.password-rules
  margin-top: 1rem !important
  list-style-type: none

.password-rules li
  color: $pm-primary
  font-size: 0.8rem

.button
  background-color: $pm-primary !important
  border-color: $pm-primary !important







