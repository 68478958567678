@import "~bootstrap/scss/bootstrap"
@import "./../../basedata/variables"



   
.upload-check-table,
.sample-error-list
  table
    width: 100%

    thead
      background-color: $pm-primary

      th
        padding-left: 16px
        color: $white
        text-transform: uppercase
        font-weight: bold
        font-size: 12px
    
    tbody
      tr
        border-bottom: 1px solid $gray-200

      td, th
        padding-left: 16px
        font-size: 14px
        color: $gray-500

      th
        color: $gray-700

    select
      font-size: 16px
      border-radius: 25px
      font-weight: 300
      color: $gray-600
      background-image: url('../../assets/images/arrow-down.svg')
      background-position: right 0.75rem center
      background-repeat: no-repeat
      background-size: 0.75em 0.75em
      padding-right: 2.5rem
      -webkit-print-color-adjust: exact
      print-color-adjust: exact
      background-color: $gray-200
      padding-right: 16px
      appearance: none
      -webkit-appearance: none
      padding: 10px 16px
      min-width: 160px

    .btn-muted
      background: none
      border: none

    .btn-exclude
      color: $pm-dark
  .align-right
    text-align: right


.sample-error-list
  th,td
    padding: 12px
    font-size: 14px

// button
.button-default
    min-width: 120px
    background-color: $pm-primary
    color: $white
    padding: 10px 8px
    margin-top: 20px
    border: 0

    &.button-share
        min-width: 20px
        margin-right: 8px
        font-size: 10px
        text-transform: uppercase
        margin-top: 4px
        background-color: $blue-400

    &.btn-info
        background-color: $blue-400

.status-circle
    width: 10px
    height: 10px
    background-color: $gray-300

    &.nok
        background-color: $red-500

    &.ok
        background-color: $green-500

.button-icon
    width: 10px
    margin-left: 8px
    display: inline

.share-text
    font-size: 9px
    text-transform: uppercase
    line-height: 12px
    margin: 0
    color: $gray-500

.show-numbers
  background-color: $white
  overflow: hidden

  .big-numbers
    color: $white
    font-size: 80px
    text-align: center
    padding: 10px 0
    background-color: $blue-300
    border-bottom-left-radius: 70% 100%
    border-bottom-right-radius: 70% 100%

  .numbers-description
    font-size: 24px
    line-height: 32px
    padding: 30px 40px
    color: $gray-500
    text-align: center
    min-height: 160px

    span
        color: $pm-primary

.error-numbers
  .big-numbers
    background-color: $red-400
  

.fake-link
  font-size: 11px
  text-transform: uppercase

.soft-red
  color: $red-300

  &:hover
      color: $red-600

.action-table-header
  padding: 16px

.bg-red-600
  background-color: $red-500


.sample-link
  display: inline-block
  width: 100%
  font-size: 11px
  text-transform: uppercase
  color: $blue-500
  padding-top: 16px
  margin-top: 16px
  border-top: 1px solid $blue-200
  text-align: center

  svg
    width: 16px
    height: 16px
    color: $gray-500
    display: inline-block
    margin-right: 8px

// Se foder, só descomentar :)
// .small-numbers
//     background-color: $white
//     font-size: 18px
//     line-height: 24px
//     color: $gray-700
//     text-transform: uppercase
//     border: 1px solid $gray-400
//     overflow: hidden

//     .small-chart
//         width: 120px
//         padding: 16px
//         position: relative

//         img
//             height: 80px
//             width: auto 

//         .chart-values
//             font-size: 16px
//             font-weight: 700
//             color: $blue-500
//             position: absolute
//             top: 45%
//             left: 37%

//     .small-description
//         margin-left: 16px

//     span
//         font-size: 14px
//         color: $gray-400
//         display: block

//     .numbers-value
//         color: $gray-900
//         font-weight: 400
//         font-size: 32px
//         margin-top: 8px

.big-charts
    background-color: $blue-300
    border-bottom-left-radius: 70% 20%
    border-bottom-right-radius: 70% 20%
    padding: 16px 0

    .chart
        width: 60%
        margin: 0 auto


.modal-drag-drop
    font-size: 11px
    text-transform: uppercase
    color: $gray-600
    text-align: center

.pagination
    .active a
        background-color: $pm-primary


  
// JEff
.hidden
  display: none !important

.event-action-top
  display: flex
  justify-content: space-between
  margin-bottom: 16px

.square-button
  width: 140px
  height: 90px
  font-size: 12px
  line-height: 14px
  padding-top: 15px
  text-align: center
  text-transform: uppercase

  span
    color: $gray-600

  .custom-icon
    width: 20px
    display: inline-block

  span
    display: block
    margin-top: 15px

.all-event-buttom
  background-color: $gray-400

  .big-counter
    font-size: 40px
    line-height: 0
    padding-bottom: 16px
    margin-top: 24px
    color: #000

.call-buttom
  background-color: $bepass

  span
    color: $white

  .custom-icon
    font-size: 40px
    width: 40px

    svg
      color: $white
      fill: $white

.custom-icon-svg
  width: 10px
  color: $white
  fill: $white

.event-action-top-buttons, .event-action-top-info
  display: flex
  width: 50%

.event-action-top-info
  justify-content: flex-start

  .square-button
    margin-right: 4px
    background-color: $white

    span
      display: block
      margin-top: 0
      line-height: 29px

    .event-info-month
      font-size: 20px

    .event-info-date
      font-size: 36px
      color: $gray-700

    &.event-title
      width: 320px
      padding-left: 16px

      .event-info-time
        font-size: 32px

      .event-info-title
        font-size: 16px
        line-height: 40px
        color: $black

      span
        text-align: left

.event-action-top-buttons
  justify-content: flex-end

  .square-button
    margin-left: 16px

h2.table-title
    font-size: 3em
    color: $gray-800
    margin: 32px 0

.dropdown-box
  position: relative
  z-index: 10
  background-color: $white
  border: 1px solid $gray-300
  border-radius: 8px

.dropdown-list
  list-style: none
  margin-left: 0
  padding-left: 0
  
  &.dropdown-list-hr
    border-top: 1px solid $gray-400

  li
    a
      display: block
      width: 100%
      font-size: 14px
      line-height: 20px
      color: $gray-700
      text-decoration: none

      &:hover
        background-color: $gray-200
        cursor: pointer

.table-action-search
  background-color: $gray-100
  border: 1px solid $gray-200
  padding: 8px
  border-radius: 8px
  min-width: 300px

  svg
    fill: $gray-700
    color: $gray-700

  input
    width: 90%
    font-size: 14px
    color: $gray-700
    border: none
    background-color: $gray-100
    margin-left: 8px

.table-people-header
  background-color: $gray-100
  text-transform: uppercase

  th
    font-size: 12px
    color: $black
    font-weight: bold
    padding: 16px 0 !important

    &:nth-child(1)
      padding-left: 16px !important

.table-people-data *
    font-size: 0.9rem
    line-height: 1rem
    color: $gray-500

.table-people-info
  display: flex
  align-items: center
  flex-wrap: nowrap

  
  div
    margin-left: 16px
    margin-top: 4px

  img
    max-width: 40px

  h3
    font-size: 1rem
    line-height: 1.5rem
    color: $black
    font-weight: 700
    margin: 0

  h4
    font-size: 1rem
    line-height: 1.5rem
    color: $gray-500
    font-weight: normal

.status-circle
  width: 10px
  height: 10px
  background-color: $green-500
  border-radius: 100%
  display: inline-block
  margin-right: 4px

  &.nok
    background-color: $red-500

.share-text
  font-size: 9px
  text-transform: uppercase

.table-people-single-action
  p
    margin-bottom: 4px

  button
    background-color: $blue-300
    border: 0
    color: $white
    font-size: 10px
    text-transform: uppercase
    border-radius: 8px
    padding: 8px 16px
    margin-right: 8px
    
.fake-link
  font-size: 10px
  text-transform: uppercase
  border: 0
  background-color: transparent
  &.soft-exclude
    color: $red-300

//Modal
.dropfile-modal
  h3
    font-size: 20px
    color: $gray-700

  .modal-header
    border-bottom: 0 !important

.dropfile-box
  border: 1px solid $gray-300
  padding: 64px 32px
  border-radius: 8px
  text-align: center

  p
    color: $gray-700
    font-size: 14px

  .modal-drag-drop
    z-index: 0

.sample-link
  display: block
  text-align: center
  font-size: 14px
  color: $gray-800
  text-transform: uppercase
  text-decoration: none

  i
    font-size: 20px
    margin-right: 8px


// new
.old-event-list
  list-style: none
  margin: 0
  padding: 0

  li
    margin-bottom: 16px
    
    .square-button
      width: 40px
      height: 40px
      padding-top: 11px

      .event-info-month
        font-size: 14px
        line-height: 16px

    .custom-icon
      font-size: 20px
    
    .event-action-top-buttons
      width: 30%

      .all-event-buttom
        width: 50%
        padding-top: 0

        .big-counter
          font-size: 14px
          margin: 14px 0 0 0
          padding: 0

        span
          margin-top: 10px
          
  .event-action-top-info
    width: 70%

    .square-button.event-title
      width: 100%
      text-align: left
      padding-top: 0px
      margin-left: 16px

      .event-info-time,
      .event-info-title
        display: inline
        font-size: 14px
        margin-right: 8px
